<template>
  <div class="questionnaires">
    <CompleteDialog ref="completeDialog" />
    <div v-if="loading" class="mt-10 loading-container">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
  </div>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import QuestionnaireEnum from '@/enumerations/questionnaires_enum'
import CompleteDialog from './complete_dialog'
export default {
  name: 'schedule_questionnarires',
  components: { CompleteDialog },
  data: () => ({
    loading: false,
  }),

  mounted() {
    this.fetch()
  },

  computed: {
    disabled() {
      const layout = this.layouts[this.step]
      return layout?.status === QuestionnaireEnum.status.finished
    },
  },

  methods: {
    async fetch() {
      try {
        this.loading = true
        const token = this.$route.params.token

        const response = await Api.core.schedules.questionnaires(token)
        const schedule = response.data.schedule

        if (
          schedule.questionario_agendamento?.link_questionario_agendamento &&
          schedule.questionario_agendamento?.status_resposta !==
            QuestionnaireEnum.status.finished
        ) {
          window.location.href =
            schedule.questionario_agendamento.link_questionario_agendamento
          return
        }

        if (
          schedule.questionario_realizacao?.link_questionario_realizacao &&
          schedule.questionario_realizacao?.status_resposta !==
            QuestionnaireEnum.status.finished
        ) {
          window.location.href =
            schedule.questionario_realizacao.link_questionario_realizacao
          return
        }

        this.$refs.completeDialog.show()
      } catch (err) {
        if (err.response) {
          if (err?.response?.status != 500) {
            this.$toast.show(err.response.data.errors.join(','), 'error')
          } else {
            this.$toast.show('Erro ao buscar dados', 'error')
          }
        } else {
          throw err
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.questionnaires {
  flex-direction: column;
  padding: 1rem;
  width: 90%;
  max-width: 748px;

  .loading-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }
}
</style>

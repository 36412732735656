export const MAP = {
  SHORT_TEXT: 2,
  LONG_TEXT: 3,
  LIST: 4,
  GROUP: 5,
  CHECKBOX: 6,
  FLOAT: 7,
  INT: 8,
  DATE: 9,
  SCALE: 10,
  HTML: 17,
}
export default {
  fields: [
    { kind: 'short_text', cod: MAP.SHORT_TEXT, response_field: 'valor_string' },
    { kind: 'long_text', cod: MAP.LONG_TEXT, response_field: 'valor_texto' },
    { kind: 'list', cod: MAP.LIST, response_field: 'valor_string' },
    { kind: 'group', cod: MAP.GROUP, response_field: 'valor_string' },
    { kind: 'checkbox', cod: MAP.CHECKBOX, response_field: 'valor_logico' },
    { kind: 'float', cod: MAP.FLOAT, response_field: 'valor_numerico' },
    { kind: 'int', cod: MAP.INT, response_field: 'valor_numerico' },
    { kind: 'date', cod: MAP.DATE, response_field: 'valor_data' },
    { kind: 'scale', cod: MAP.SCALE, response_field: 'valor_string' },
    // { kind: 'html', cod: MAP.HTML, response_field: 'valor_html' },
  ],
  status: {
    pending: 1,
    in_progress: 2,
    finished: 3,
  },
  // SHORT_TEXT: 2, // 2 'Texto curto';
  // LONG_TEXT: 3, // 3 'Texto longo';
  // LIST: 4, // 4 'Lista de opções';
  // GROUP: 5, // 5 'Grupo de opções';
  // CHECKBOX: 6, // 6 'Caixa de seleção';
  // FLOAT: 7, // 7 'Número fracionário';
  // INT: 8, // 8 'Número inteiro';
  // DATE: 9, // 9 'Data';
  // SCALE: 10, // 10 'Escala';
  // 11 'Modelo de laudo';
  // 12 'Fórmula matemática';
  // 13 'Fórmula de data';
  // 14' Valor de tabela';
  // 15' Valor de tabela condicional';
  // 16' Referência';
  // 17 'Texto formatado HTML';
  // 18 'CID';
  // 19 'Prescrição de óculos';
  // 99 'Preparo do procedimento';
  // INITIALIZED: 1,
  // FINISHED: 2,
  // PATIENT: 3,
  // EXAMS: 4,
  // COVENANTS: 5,
}
